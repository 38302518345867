import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import 'react-select/dist/react-select.css';

export default class SingleSelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
        selected: null
    }
  }

  filterValues(row, term, columnKey) {
      if(!row[columnKey] && term === "<Blanks>") {
          return true;
      }
    return row[columnKey] == term;
   }

  handleChange(selected) {
      this.setState({selected}, () => {
        this.props.onChange({ filterTerm: selected ? selected.value : null, column: this.props.column, rawValue: selected, filterValues: this.filterValues });
      })
  }


  render() {
    const inputKey = 'header-filter-' + this.props.column.key;
 
    return (
        <Select
        key = {inputKey}
        value={this.state.selected}
        options={this.props.options}
        onChange={this.handleChange}
        closeMenuOnSelect={false}
         />);
  }
}

SingleSelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired

};
