import React from 'react';
import ReactPaginate from 'react-paginate';
export default function Paginate(props) {
  return <div className="pagination-wrapper">
    <span className="perpage">Per Page: </span>
    <div className="styled-select right-margin">
      <select onChange={props.onPerPageChange} value={props.perPage} >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
      </select>
    </div>
            <ReactPaginate
                previousLabel={'Prev'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={props.onPageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={props.forcePage}
            />
      </div>
  }