//Dev
// module.exports = {
//   appId: "71ab2ff1-22f5-47f6-ac5d-ff1e6537ebef",
//   redirectUri: window.location.origin,
//   scopes: [
//     'user.read',
//     "api://71ab2ff1-22f5-47f6-ac5d-ff1e6537ebef/finance_user"
//   ],
//   authority: "https://login.microsoftonline.com/5a9d9cfd-c32e-4ac1-a9ed-fe83df4f9e4d"
// };

//Production
module.exports = {
   appId: "f0f57711-043c-4172-ab77-4999c2bddc63",
   redirectUri: window.location.origin,
   scopes: [
       'user.read',
       "api://f0f57711-043c-4172-ab77-4999c2bddc63/finance_user"
   ],
   authority: "https://login.microsoftonline.com/5a9d9cfd-c32e-4ac1-a9ed-fe83df4f9e4d"
};