import React from 'react';
import Paginate from '../Paginate';
import { CSVLink } from "react-csv";
import TableHeader from './TableHeader';
// import { saveAs } from 'file-saver';
const defaultColumnProperties = {
  filterable: true,
  className: "rowname left-align"
};
const perPage = 100;
export default class Table extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.downloadLink = React.createRef();
    this.tableHeader = React.createRef();
    this.tableBody = React.createRef();

    this.tableposition = 0;
      this._columns = [
        
        {
          key: "TeamMember",
          name: "Owned By",
          filterRenderer: "MultiSelectFilter",
          width: 280
        },
        {
            key: "UserEmail",
          name: "Email",
          filterRenderer: "SingleSelectFilter",
          width: 250
        },
        {
            key: "EmplID",
          name: "Emp ID",
          width: 150,
        },
        {
            key: "Company",
            name: "Company",
            width: 180,
          filterRenderer: "SingleSelectFilter"
          },
      //  {
      //    key: "resourceManager",
      //    name: "Resource Manager",
      //    width: 250,
      //    filterRenderer: "MultiSelectFilter"
      //},
      {
          key: "AssignedApproverUserName",
        name: "Approval Manager",
        width: 250,
        filterRenderer: "MultiSelectFilter"
      },
      {
          key: "TeamProject",
        name: "Workstream",
        width: 250,
        filterRenderer: "MultiSelectFilter"
      },
      {
          key: "TopTFSTitle",
        name: "Project",
        width: 300
      },
      {
          key: "TopTFSID",
        name: "Azure DevOps ID",
        width: 120,
        className: "rowname right-align"
      },
        {
            key: "WorkIntakeID",
          name: "Work Intake ID",
          width: 120
      },
      {
          key: "TopParentType",
        name: "Workitem Type",
        width: 150,
        filterRenderer: "MultiSelectFilter"
        },
           
      {
          key: "Comment",
        name: "Comment",
        width: 300
      },
 
        {
            key: "ConvertedDate",
          name: "Created Date",
          width: 125,
          className: "rowname right-align"
      },
      {
          key: "week",
        name: "Week",
        width: 175,
        filterRenderer: "MultiSelectFilter"
      },
      {
          key: "ProjectType",
        name: "Project Type",
        width: 150,
        filterRenderer: "MultiSelectFilter"
      },
        {
            key: "ApprovalState",
          name: "Approval State",
          filterRenderer: "MultiSelectFilter",
          width: 180
      },
      {
        key: "TimeTracked",
        name: "Hours",
        width: 100,
        filterRenderer: "NumericalFilter",
        className: "rowname right-align"
        },
        {
            key: "ActivityType",
            name: "Activity Type",
            width: 150,
            filterRenderer: "MultiSelectFilter"
        },   
        {
            key: "FinanceID",
            name: "Finance ID",
            width: 150
          },
          {
            key: "CapEx",
            name: "Cap / Ex",
            width: 100,
            filterRenderer: "SingleSelectFilter"
        },
        {
            key: "FinanceCapEx",
            name: "Finance CapEx",
            width: 120,
            filterRenderer: "SingleSelectFilter"
          },
          {
              key: "RowID",
              name: "Row ID",
              width: 280
          }
        
      ].map(c => ({ ...defaultColumnProperties, ...c }));

      this._headers = this._columns.map( item => {
        return {
          label:item.name,
          key: item.key
        }
      })
    this._headerslen = this._headers.length;

    this.state = { 
      rows: this.props.rows.slice(0, perPage),
      timesheets: this.props.rows,
      perPage: perPage,
      pageCount: Math.ceil(this.props.rows.length / perPage),
      filters: [], 
      enableHeaderFilters: false, 
      selected:[], 
      shortfilters:null, 
      selectedItems: {},
      currentPage: 0,
      start_time: this.props.start_time,
      downloadCSV: false,
    };     
  }

  onTableScroll = (e) => {
    const tableposition = e.target.scrollLeft;
    if (tableposition !== this.tableposition) {
      this.tableHeader.current.style.marginLeft = `-${tableposition}px`;
      this.tableposition = tableposition;
    }
  }

  EnableFilter = () => {
    this.getDropDownValues();
  }

  getDropDownValues = () => {
    var currentstate = this.state.enableHeaderFilters;
    let shortfilters = {};
    if(!currentstate) {
     shortfilters = this._columns.reduce((prev, current) => {
        if(current.filterRenderer === "MultiSelectFilter" || current.filterRenderer === "SingleSelectFilter") {
          prev[current.key] = Array.from(
             new Set(
               this.props.rows.map(row => row[current.key] ? row[current.key] : "<Blanks>")
             )).sort()
             .map(col => {
               return {
                 label: col,
                 value: col
               }
             })            
        }
        return prev;
  
      }, {})
    } 

    this.setState({
      enableHeaderFilters: !this.state.enableHeaderFilters, 
      filters: [], 
      shortfilters: shortfilters, 
      rows: this.props.rows.slice(0, this.state.perPage),
      timesheets: this.props.rows,
      currentPage: 0,
      pageCount: Math.ceil(this.props.rows.length / this.state.perPage),
    });
  }

  applyfilters = (filter) => {
    const prevFilters = this.state.filters.filter(item => item.column.key !== filter.column.key );

    if(filter.filterTerm) {
      prevFilters.push(filter);
    }
    let timesheets = this.props.rows;
    if(prevFilters.length > 0) {
      timesheets = timesheets.filter((row) => {

        return prevFilters.every((item) => {
          return item.filterValues(row, item.filterTerm, item.column.key);
        })
   
       });
    }
  
    this.setState({
      filters: prevFilters,
      timesheets,
      rows: timesheets.slice(0, this.state.perPage),
      pageCount: Math.ceil(timesheets.length / this.state.perPage),
      currentPage: 0
    });

  }
  static getDerivedStateFromProps(props, prevstate) {
   
    if(props.start_time!==prevstate.start_time && props.submitted){
      return {
        rows: props.rows.slice(0, prevstate.perPage),
        timesheets: props.rows,
        pageCount: Math.ceil(props.rows.length / prevstate.perPage),
        filters: [],
        enableHeaderFilters: false,
        shortfilters:null,
        selectedItems: {},
        currentPage: 0,
        start_time: props.start_time
      };
    }
     return null;
    
  }
  handlePageClick = (data) => {
    const offset = data.selected * this.state.perPage;
    const rows = this.state.timesheets.slice(offset, this.state.perPage+offset);
    this.setState({ rows, currentPage: data.selected }, () => {
      this.tableBody.current.scrollTop = 0;
    });
  }

  onPerPageChange = (e) => {
    const perPage = parseInt(e.target.value);
    const pageCount = Math.ceil(this.state.timesheets.length / perPage);

    this.setState({
      perPage: perPage,
      rows: this.state.timesheets.slice(0, perPage),
      currentPage: 0,
      pageCount
    }, () => {
        this.tableBody.current.scrollTop = 0;

    })
  }

   downLoadEcel = (e) => {
     e.preventDefault();
     if(!this.state.downloadCSV && this.state.rows.length > 0) {
      this.setState({downloadCSV: true},  () => {
        setTimeout(() => {
          this.downloadLink.current.link.click()
          setTimeout(() => {
            this.setState({downloadCSV: false});
          }, 5000);
        }, 1000);
     
      });
    }
   }
   GetcolGroup = ()  => {
    return this._columns.map((column) =>
      <col key={column.key} style={{width: column.width+"px"}} /> 
    );      
  }

  render() {
    const {
      shortfilters, 
      enableHeaderFilters, 
      currentPage, 
      pageCount,
      rows,
      downloadCSV,
      timesheets
    } = this.state;
    const filterText = enableHeaderFilters ? "Reset filter" : "Enable filter";
    const filterclass = enableHeaderFilters ? "filter-opened" : "";
    const colGroupList = this.GetcolGroup();
    return (
        <>
        <div className="table-buttons">
        <a href="#" onClick={this.downLoadEcel}>Download as Excel</a>
         {downloadCSV && rows.length > 0 && 
          <CSVLink 
            data={timesheets} 
            headers={this._headers}
            filename={`Internal Capitalization ${ this.props.startDate } - ${this.props.endDate}.csv`}
            ref={this.downloadLink}
            style={{display: "none"}}
          >
            Download as Excel
          </CSVLink>
        }
          <button className="filter-button" onClick={this.EnableFilter}>{filterText}</button>
              <Paginate
            pageCount={pageCount}
            onPerPageChange={this.onPerPageChange}
              onPageChange={this.handlePageClick}
            forcePage={currentPage}
            perPage={this.state.perPage}
            />          
        </div>
        <div className={`table-wrapper ${filterclass}`}>
          <div className="table-header" ref={this.tableHeader}>
            <table>
              <colgroup>
                {colGroupList}
              </colgroup>
              <TableHeader
                columns={this._columns}
                enableHeaderFilters={enableHeaderFilters}
                shortfilters={shortfilters}
                applyfilters={this.applyfilters}
              />
              </table>
          </div>
          <div className="table-body" ref={this.tableBody} onScroll={this.onTableScroll}>
            <table>
              <colgroup>
                {colGroupList}
              </colgroup>
            <tbody>
              {rows.map((item, key) => (
                <tr key={key}>
                  {this._columns.map(column => (
                    <td className={column.className} key={column.key}>{item[column.key]}</td>
                  ))}
                </tr>
              ))}
              {rows.length == 0 &&
                 <tr><td colSpan={this._headerslen} className="empty"><div>No data available</div></td></tr>
              }
              </tbody>
            </table>
          </div>
        
        </div>
        <div className="table-buttons bottom-buttons">
          <Paginate
            pageCount={pageCount}
            onPerPageChange={this.onPerPageChange}
            onPageChange={this.handlePageClick}
            forcePage={currentPage}
            perPage={this.state.perPage}
        />
        </div>
        </>
    );
  }
}