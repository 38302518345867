import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faCalendarAlt, faCheck } from '@fortawesome/free-solid-svg-icons'
import "react-datepicker/dist/react-datepicker.css";
import Table from "./table";
const signalR = require("@microsoft/signalr");

function formatDate(date) {
	let d = new Date(date);
	let month = d.getMonth()+1;
	let day = d.getDate();
	 month = month > 9 ? month : `0${month}`;
	 day = day > 9 ? day : `0${day}`;
	return month + "/" + day + "/" + d.getFullYear();
  }
const messages = [
		"Your request is in progress, please wait...",
		"Report fetched, please click on show data to view the report"
];
const steps = [5, 10, 20, 40];
export default class BgProcess extends Component {
	state = { 
		startDate: '', 
		endDate: '', 
		timesheets: [], 
		submitted: false,
		progress: false,
		error: false,
		errorMessage: '',
		IsSubmitBtnDisabled: false,
			IsShowBtnDisabled: true,
			timesheetCatch: null,
			processsMessage: messages[0]
	};
	startDateRef = React.createRef();
	 endDateRef = React.createRef();
	getTimeSheets = e => {
			e.preventDefault();
			var t = [],
				n = 0,
				r = 0,
				s = 0;
			this.fetchData()
				.then(response => {
						if (response && response.jobId) {

								var connection = new signalR.HubConnectionBuilder()
										.withUrl("/jobprogress")
								.build();
								connection.serverTimeoutInMilliseconds = 1000 * 60 * 30;
								connection.on("progress",
										(res) => {
										var statusProgress = JSON.parse(res)														
										this.setProgressRange(statusProgress);
												
									});
							connection.onclose(err => {
								if (err || r != n)
									this.setState({ progress: false, error: true, errorMessage: "Something went wrong, please refresh the page and try again" });
							})
							connection.on("stepsProgress",
								(res) => {
									s = 60 / (n = res)

								});
							connection.on("errorMessage",
								(res) => {
									connection.stop();
									this.setState({
										progress: false,
										error: true,
										errorMessage: JSON.parse(JSON.parse(res).ErrorMessage).Message
									})
								});
							connection.on("dataProgress",
								(res) => {
									var data = JSON.parse(res);
									t = t.concat(data.Data);
									if (r < n) {										
										++r;
										var o = Math.round(40 + r * s);
										this.setpercentage(o)
									}
									if (r == n) {
										connection.stop();
										var c = {
											data: t
										}
										this.setState({
											timesheetCatch: c,
											IsShowBtnDisabled: !1,
											processsMessage: messages[1]
										}, () => {
											this.setpercentage(100)
										})
                                    }																	
								});
								connection.start()
										.then(_ => connection.invoke("AssociateJob", response.jobId))
									.catch(err => {

										this.setState({ progress: false, error: true, errorMessage: err.toString() });
										console.error(err.toString())

									});

						} else {
								if (response)
										this.setState({ progress: false, error: true, errorMessage: response });

						}
				});	
	}

		fetchData = async (uri = "/api/TimeSheets/bulkData") => {
				const { startDate, endDate } = this.state;
				let errorMessage;
				if (!startDate || !endDate) {
						errorMessage = "Please select start and end dates";
						return errorMessage;
				}
				var date1 = new Date(startDate);
				var date2 = new Date(endDate);

				var Difference_In_Time = date2.getTime() - date1.getTime();

				var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
				if (Difference_In_Days > 35 || Difference_In_Days < 0) {
						errorMessage = Difference_In_Days > 35 ? 'Date range should be less than or equal to 35 Days' : "Start date should be greater than end date";
						return errorMessage;
				}

				let url = `${uri}?StartDate=${formatDate(startDate)}&Enddate=${formatDate(endDate)}`;

				this.setState({
						progress: true,
						error: false,
						submitted: false,
						IsSubmitBtnDisabled: true,
						processsMessage: messages[0],
						timesheets: []
				});
				var accessToken = this.props.getApiToken();

				if (accessToken) {
						const myRequest = new Request(url, {
								method: 'GET',
								mode: 'cors',
								cache: 'default',
								headers: {
										Authorization: 'Bearer ' + accessToken,
								}
						});
						let response = await fetch(myRequest);
						if (response.ok) {
								let data = await response.json();
								this.setProgressRange(0);
								return data;
						} else {
								return "Something went wrong, please refresh the page and try again";
						}				
				}

		}
  
		onStartDate = date => {
	  this.setState({ startDate: date, error: false, submitted: false })
	}
	onEndDate = date => {
		this.setState({ endDate: date, error: false, submitted: false })
		}
		showData = () => {
				this.setState({
						progress: false,
						IsSubmitBtnDisabled: false,
						IsShowBtnDisabled: true,
						timesheets: this.state.timesheetCatch.data,
						submitted: true
				}, () => {
								this.setState({
										timesheetCatch: null
								});
				});
		}

	setProgressRange = (step) => {
		var t = steps[step];
		this.setpercentage(t)
	}
	setpercentage = (stepPer) => {
		var $circle = document.querySelector('#bar');
		var r = $circle.getAttribute('r');
		if (stepPer < 0) {
			stepPer = 0;
		}
		else if (stepPer > 100) {
			stepPer = 100;
		}
		var pct = (100 - stepPer) / 100 * (Math.PI * (2 * r));

		$circle.style.strokeDashoffset = pct;
		document.querySelector('#cont').dataset.pct = stepPer;
    }

	render() {
      const {
          startDate, 
          endDate, 
          timesheets, 
          submitted, 
          progress,
          error,
										errorMessage,
										IsSubmitBtnDisabled,
										IsShowBtnDisabled,
										timesheetCatch
        } = this.state;
	  return (
		<div className="container">
		<form id="contact" action="" onSubmit={this.getTimeSheets} method="post">
			<div className="d-flex">
			<fieldset className="d-flex-item">
				<label>Start date</label>
				<label className="date-label" onClick={e => e.preventDefault()}>
				<DatePicker 
					selected={startDate} 
					onChange={this.onStartDate} 
					maxDate={new Date()}
					ref={this.startDateRef}
				/>
				<FontAwesomeIcon onClick={() => { this.startDateRef.current.setOpen(true) }} icon={faCalendarAlt} />
			
				</label>
				
    		</fieldset>
			<fieldset className="d-flex-item">
				<label>End date</label>
				<label className="date-label" onClick={e => e.preventDefault()}>
				<DatePicker 
					selected={endDate} 
					onChange={this.onEndDate}
					ref={this.endDateRef}
															/>
				<FontAwesomeIcon onClick={() => { this.endDateRef.current.setOpen(true) }} icon={faCalendarAlt} />
				</label>
				
    		</fieldset>
			
			</div>
			
			<div className="d-flex d-flex-button-group">
			{error &&
				<div>
					<p className="error">{errorMessage}</p>
				</div>
			}	
					<div className="d-flex-item right-margin">
													<button name="submit" type="submit" disabled={IsSubmitBtnDisabled} className="submit-btn send-btn" value="TimeSheets" data-submit="...Sending">Send request</button>
				</div>
											<div className="d-flex-item">
													<button name="showdata" onClick={this.showData} disabled={IsShowBtnDisabled} type="button" className="submit-btn show-btn" value="TimeSheets">Show data</button>
				</div>
    </div>
		</form>
		{timesheets.length > 0 &&
			<div className="container">
				<Table 
				rows={timesheets} 
				start_time={new Date().getTime()}
				submitted={submitted}
				startDate={formatDate(startDate)}
				endDate={formatDate(endDate)}
				/>			
			</div>
		}
		{(timesheets.length == 0 && submitted) &&
			<div className="container">
				<p className="emptyAlert">Timesheet data not available for the selected date range.</p>
			</div>
		}
						
							{progress &&						
									<div className={`progress-info  ${!timesheetCatch ? 'info-msg' : 'success-msg'}`}>
									<div id="cont" data-pct="0">
											<svg id="svg" width="100" height="100" viewPort="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
													<circle r="45" cx="50" cy="50" fill="transparent" stroke-dasharray="282.74"  stroke-dashoffset="0"></circle>
													<circle id="bar" r="45" cx="50" cy="50" fill="transparent" style={{ "stroke-dashoffset": "282.74px" }} stroke-dasharray="282.74" stroke-dashoffset="0"></circle>
											</svg>
									</div>
											<p>
											<FontAwesomeIcon icon={!timesheetCatch ? faInfoCircle : faCheck} className="right-margin" />
													{this.state.processsMessage}
											</p>
									</div>							
							}
						
					</div>

	  );
	}
  }
