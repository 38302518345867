import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { UserAgentApplication } from 'msal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import config from './Config';
import NavBar from './Components/NavBar';
import BgProcess from './routes/home/bgProcess';
import extractMSALToken, { checktokenExpiry } from './IdToken';
import './App.css';

class App extends Component {
    constructor(props) {
        super(props);

        this.userAgentApplication = new UserAgentApplication({
            auth: {
                clientId: config.appId,
                redirectUri: config.redirectUri,
                authority: config.authority,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        });

        this.userAgentApplication.handleRedirectCallback(this.authCallback);

        var user = this.userAgentApplication.getAccount();
        let isAllowed = false;

        if (user) {
            var isExpired = checktokenExpiry();
            if (!isExpired) {
                if (user.name) {
                    user.name = user.name.split(",").reverse().join(" ");
                }
                if (user.idToken && user.idToken.roles) {
                    isAllowed = user.idToken.roles.includes('Finance');
                }
            }
        }
        if (!user || isExpired) {
            user = null;
            this.login();
        }
        this.state = {
            isAuthenticated: (user !== null),
            user: user,
            error: null,
            isAllowed: isAllowed,
        };
    }
    authCallback = (error, response) => {
    }

    render() {
        const { isAuthenticated, user, isAllowed, error } = this.state;

        return (
            <Router>
                <div>
                    {(isAuthenticated || error) &&
                        <NavBar
                            isAuthenticated={isAuthenticated}
                            authButtonMethod={isAuthenticated ? this.logout : this.login}
                            user={user} />
                    }
                    {isAuthenticated && isAllowed &&
                        <Route exact path="/"
                            render={(props) =>
                                <BgProcess {...props}
                                    isAuthenticated={this.state.isAuthenticated}
                                    getApiToken={this.getApiToken}
                                    user={this.state.user}
                                />
                            } />
                    }
                    {isAuthenticated && !isAllowed &&
                        <div className="no-access">
                            You don't have permission to access the Ashley Capitalization Report.
           </div>
                    }
                    {!isAuthenticated && !error &&
                        <div className="login-progress">
                            Processing..
           </div>
                    }
                    {error &&
                        <div className="login-error">
                            <FontAwesomeIcon icon={faExclamationTriangle} size="lg" /> {error}
                        </div>
                    }
                </div>
            </Router>
        );
    }

    login = async () => {
        try {
            await this.userAgentApplication.loginRedirect(
                {
                    scopes: config.scopes
                });
        }
        catch (err) {
            var error = null;
            if (typeof (err) === 'string') {
                var errParts = err.split('|');
                error = errParts.length > 1 ? errParts[1] : err;
            } else {
                error = err.message
            }
            this.setState({
                isAuthenticated: false,
                user: null,
                error: error
            });
        }
    }

    logout = () => {
        this.userAgentApplication.logout();
    }
    getApiToken = () => {
        try {
            return extractMSALToken();
        }
        catch (e) {
            this.setState({
                isAuthenticated: false,
                user: {},
                error: "Your session expired, Please signin again"
            });
        }
    }
}

export default App;