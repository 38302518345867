import React from 'react';
import PropTypes from 'prop-types';

function debounce(callback, wait) {
  let timeout;
  return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => callback.apply(context, args), wait);
  };
}

export default class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  componentWillMount = () => {
    this.TextSearchDebounce = debounce( e => {
      const value = e.target.value.trim();
      this.props.onChange({ filterTerm: value ? value : null, column: this.props.column, rawValue: value, filterValues: this.filterValues });
    }, 500);
  }
  filterValues(row, term, columnKey) {
      
      if(!row[columnKey] && term.toLowerCase() === "<blanks>") {
            return true;
     }
     else if(!row[columnKey])
        return false;
       return row[columnKey].toLowerCase().includes(term.toLowerCase());
  }

  handleChange(e) {
    e.persist();
    this.TextSearchDebounce(e);
  }


  render() {
    const inputKey = 'header-filter-' + this.props.column.key;
 
    return (
          <input key={inputKey} type="text" placeholder="Search..." className="form-control input-sm" onChange={this.handleChange} />
    );
  }
}

TextFilter.propTypes = {
  onChange: PropTypes.func.isRequired
};
