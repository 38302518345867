import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignInAlt, faSignOutAlt} from '@fortawesome/free-solid-svg-icons'

function NavBar(props) {
  const [showDropdown, showHideDropdown] = useState(
    false
  );
  const handleWindowClick = (e) => {
    if (!e.target.closest('.avatarmenu-header') && showDropdown)
      showHideDropdown(false);
  }
  useEffect(() => {   
    document.addEventListener("click", handleWindowClick);
    return () => window.removeEventListener("onClick", handleWindowClick);
  });

  const handleDropdownMenu = (e) => {
    if (!e.target.closest('.avatar-dropdown'))
        showHideDropdown(!showDropdown);
  }
  return (<nav className="top-bar">
    <div className="appname">Ashley Capitalization Report</div>
    {props.isAuthenticated ?
      <div className="avatarmenu-header" onClick={handleDropdownMenu}>
          <div className="avatarmenu-user right-margin">
          <div className="avatarmenu-username">
              {props.user.userName}
          </div>
            <div className="avatarmenu-displayname">
              {props.user.name}
          </div>
        </div>
        <div className="avatarmenu-image">
            <FontAwesomeIcon icon={faUser} size="lg"/>
          </div>
          {showDropdown && 

            <div className="avatar-dropdown">
          <div>{props.user.name}</div>
          <div onClick={props.authButtonMethod} className="authbuttons">Signout<FontAwesomeIcon icon={faSignOutAlt} /></div>
            </div>

          }
        
     </div>
        :
      <div className="avatarmenu-header">
          <div className="avatar-signin authbuttons" onClick={props.authButtonMethod}>
              <div>Signin</div> <FontAwesomeIcon icon={faSignInAlt} />
          </div>         
        </div>
      }
 </nav>);
}

export default NavBar;