export default function extractMSALToken() {
  const timestamp = Math.floor((new Date()).getTime() / 1000)
  let token = null

  for (const key of Object.keys(localStorage)) {
    if (key.includes('"authority":')) {
      const val = JSON.parse(localStorage.getItem(key))

      if (val.expiresIn) {

        if (val.expiresIn > timestamp && val.idToken === val.accessToken) {
          token = val.idToken
        }
        else {
          localStorage.removeItem(key)
        }
      }
    }
  }
  if (token) return token
  throw new Error('No valid token found')
}

export function checktokenExpiry() {
  const timestamp = Math.floor((new Date()).getTime() / 1000)
  let expired = true;
  for (const key of Object.keys(localStorage)) {
    if (key.includes('"authority":')) {
      const val = JSON.parse(localStorage.getItem(key))
      if (val.expiresIn) {

        if (val.expiresIn > timestamp && val.idToken === val.accessToken) {
          expired = false;
        }       
      }
    }
  }
  return expired;
}