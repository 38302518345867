import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "@khanacademy/react-multi-select";

export default class MultiSelectFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
        selected: []
    }
  }

  filterValues(row, term, columnKey) {
    if(!term)
      return true;
    else if(term.includes("<Blanks>") && !row[columnKey]) {
      return true;
    }
    return term.includes(row[columnKey]);
   }

  handleChange(selected) {
      this.setState({selected}, () => {
        this.props.onChange({ filterTerm: selected.length ? selected : null, column: this.props.column, rawValue: selected, filterValues: this.filterValues });
      })
  }


  render() {
    const inputKey = 'header-filter-' + this.props.column.key;
 
    return (
        <MultiSelect 
                key = {inputKey}
                options={this.props.options}
                selected={this.state.selected}
                onSelectedChanged={this.handleChange} 
                overrideStrings={this.props.overrideStrings}                      
            />   
    );
  }
}

MultiSelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired

};
