import React from 'react';
import { NumericFilter } from './NumericFilter';
import TextFilter from './TextFilter';
import SingleSelectFilter from './SingleSelectFilter';
import MultiSelectFilter from './MultiSelectFilter';
export default function TableHeader(props) {
    return (<thead>
    <tr>	
    {props.columns.map(column => (
      <th data-key={column.key} key={column.key} className={column.className}>
        <div className={`'rowname' ${column.className}`}>
           {column.name}
           </div>
       {(props.enableHeaderFilters && column.filterable) &&
           <div className="header-filter">
               {column.filterRenderer == "MultiSelectFilter" ? (
                    <MultiSelectFilter 
                       column={column}
                       options={props.shortfilters[column.key]}
                      onChange={props.applyfilters}              
                       overrideStrings={{
                         selectSomeItems: "Select...",
                         allItemsAreSelected: "All Items are Selected",
                         selectAll: "Select All",
                         search: "Search"
                     }}
                    />
                  
               ): column.filterRenderer == "SingleSelectFilter" ? (
                     <SingleSelectFilter
                     column={column}
                     options={props.shortfilters[column.key]}
                     onChange={props.applyfilters}
                     />                              
               ): column.filterRenderer == "NumericalFilter" ? (
                 <form onSubmit={e => { e.preventDefault(); }}>
                   <NumericFilter 
                     onChange={props.applyfilters}
                     column={column}
                     />
               </form>
               ) : (
               <form onSubmit={e => { e.preventDefault(); }}> 
                   <TextFilter 
                    onChange={props.applyfilters}
                    column={column}                           
                   />  
               </form>
               )
               }
               
           </div>
       }
       
       </th>
    ))
   }
   </tr>
</thead>)
  }